import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Layout } from './components/Layout';
import { HomePage } from './pages/HomePage';
import { AboutUsPage } from './pages/AboutUsPage';
import { RegistrationPage } from './pages/RegistrationPage';
// import { CalendarPage } from './pages/CalendarPage';
// import { EventsPage } from './pages/EventsPage';
// import { AssetsPage } from './pages/AssetsPage';
// import { TeamsPage } from './pages/TeamsPage';
// import { DibsPage } from './pages/DibsPage';
import { BottomBar } from './components/BottomBar';

function App() {
  return (
    <div>
    <Router>
        <Layout />
        <div className='main-content'>
          <div className='side-bar'></div>
          <div className='page'>
            <Routes>
                <Route index element={<HomePage />} />
                <Route path="/about-us" element={<AboutUsPage />} />
                <Route path="/registration" element={<RegistrationPage />} />
                
                {/* <Route path="/calendar" element={<CalendarPage />} />
                <Route path="/events" element={<EventsPage />} />
                <Route path="/assets" element={<AssetsPage />} />
                <Route path="/teams" element={<TeamsPage />} />
                <Route path="/dibs" element={<DibsPage />} /> */}
            </Routes>
            <BottomBar />
          </div>
          <div className='side-bar'></div>
        </div>
    </Router>
    </div>
  );
}

export default App;
