import '../assets/TitleBar.css';

interface TitleBarProps {
    title: string
}

export const TitleBar = ({ title }: TitleBarProps) => {
    return (
        <div className='background'>
            <div className='background-text'>
                {title}
            </div>
        </div>
    );
}