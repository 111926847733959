import '../assets/Registration.css';
import { TitleBar } from '../components/TitleBar';

export const RegistrationPage = () => {
    return (
        <div>
            <TitleBar title='Registration'/>
            <div className='title'>Youth and High School</div>
            <p>Youth & High School registration is handled through two separate systems, Rugby Xplorer and TeamSnap. For legal reasons, these steps must be completed by the player's <b>parent or guardian</b> - players under 18 must not register themselves.</p>
            <p>First, register the player to Silicon Valley Rugby Football Club with USA Rugby and Rugby NorCal using <a href="https://auth.rugbyxplorer.com.au/login?clientId=portal&codeChallenge=&redirectPath=/">Rugby Xplorer</a>.</p>
            <p>Second, sign the player up and pay dues as a member of Silicon Valley Rugby using <a href="https://registration.teamsnap.com/form/13003">TeamSnap</a></p>
            
            <h3>Fees and Dues:</h3>
            <div className="dues-table-wrapper">
                <table className="dues-table">
                    <tr>
                        <th>Division</th>
                        <th>USA Rugby & Rugby NorCal fees <br/>(paid in Rugby Xplorer)</th>
                        <th>Silicon Valley Rugby <br/>dues (paid in TeamSnap)</th>
                        <th>Total Costs</th>
                    </tr>
                    <tr>
                        <td>Under-8</td>
                        <td>$20.00</td>
                        <td>$315.00</td>
                        <td>$335.00</td>
                    </tr>
                    <tr>
                        <td>Under-10</td>
                        <td>$65.00</td>
                        <td>$365.00</td>
                        <td>$430.00</td>
                    </tr>
                    <tr>
                        <td>Under-12</td>
                        <td>$65.00</td>
                        <td>$365.00</td>
                        <td>$430.00</td>
                    </tr>
                    <tr>
                        <td>MS Boys (6th - 8th)</td>
                        <td>$80.00</td>
                        <td>$425.00</td>
                        <td>$505.00</td>
                    </tr>
                    <tr>
                        <td>MS Girls (6th - 8th)</td>
                        <td>$80.00</td>
                        <td>$365.00</td>
                        <td>$445.00</td>
                    </tr>
                    <tr>
                        <td>Jr Varsity Boys (9th & 10th)</td>
                        <td>$105.00</td>
                        <td>$425.00</td>
                        <td>$530.00</td>
                    </tr>
                    <tr>
                        <td>HS Girls (9th - 12th)</td>
                        <td>$105.00</td>
                        <td>$365.00</td>
                        <td>$470.00</td>
                    </tr>
                    <tr>
                        <td>Varsity Boys (10th - 12th)</td>
                        <td>$105.00</td>
                        <td>$425.00</td>
                        <td>$530.00</td>
                    </tr>
                </table>
            </div>

            <p>Same-household siblings get 12.5% off Silicon Valley RFC dues. The national and state governing bodies to not offer a group deal.</p>

            <h3>Steps for Rugby Xplorer:</h3>
            <ol>
                <li>
                    Log In or Create a Rugby Xplorer account 
                    <br/>If creating a new RX account, create an account for yourself, the parent. 
                    <br/>THEN create a profile for each player you want to register.
                </li>
                <li>Click Register on the left sidebar, then Find a Club in the main window</li>
                <li>
                    Select the player to be registered (not yourself - unless you're registering as a Coach, Match Official, or Admin)
                    <br/>-or-
                    <br/>click Link a New Person and create a profile for the new player
                </li>
                <li>
                    Search for "Silicon Valley Rugby". Select "Silicon Valley Rugby YOUTH & HS"
                    <br/>(not “SENIOR CLUB”)
                </li>
                <li>Select Role = "Player"; Type = "Flag Rugby" for U8, "Youth" for U10, U12, or MS, and "High School" for HS; Duration = "Season".</li>
                <li>Fill in the personal information questions, affirm the waivers, and pay for your national and state-level fees.</li>
                <li>
                    You should get a receipt, with your USA Rugby ID#.
                    <br/>Record that USA Rugby ID # for the TeamSnap form.
                </li>
            </ol>
            
            <h3>TeamSnap Registration and Dues for the 2024-2025 rugby year:</h3>
            <ol>
                <li>
                    Log In or Create a TeamSnap account:
                    <br/><a href="https://registration.teamsnap.com/form/13003">https://registration.teamsnap.com/form/13003</a>
                    <br/>If creating an account, create a profile for yourself, the parent, and follow Step 2 to add Youth/HS player(s).
                </li>
                <li>
                    Select the Youth or HS person you want to register to play, or click "+Add a
                    household member" to add a player to your household; if adding a household
                    member, skip the Email field: we don't need youth player's email addresses, and
                    you should avoid adding the parent's email address in that field.</li>
                <li>Select a registration option that suits your needs (players eligible for U12 should generally pick U12 rather than MS)</li>
                <li>Fill in the personal information questions (including USA Rugby ID#), affirm the waivers, and pay Silicon Valley Rugby membership dues.</li>
            </ol>
            <p>Welcome to Silicon Valley Rugby! Join us on Mondays and Thursdays for training. Location information appears in the TeamSnap calendar. Youth (K-8) pre-season has already begun, and High School training begins after Thanksgiving.</p>
            
            <div className='title'>Adult</div>
            <p>Adult Registration will be handled through <a href="https://xplorer.rugby/">Rugby Xplorer</a></p>
            <ol>
                <li>Log In or Create an Account</li>
                <li>Click Register and Find a Club</li>
                <li>Search for "Silicon Valley Rugby". Select SENIOR CLUB.</li>
                <li>Select Role "Player". Type "Senior Club". Duration "Season".</li>
                <li>Fill in the personal information questions and pay for your registration.</li>
                <li>Once all of this is complete, you should get a reciept, and you are ready to play Rugby with Silicon Valley!</li>
            </ol>
        </div>
    );
}