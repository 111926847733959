import '../assets/UpcomingEvents.css';

interface UpcomingEventsProps {
    title: string;
    events: { date: String, event: string }[];
}

export const UpcomingEvents = ({ title, events }: UpcomingEventsProps) => {
    return (
        <div className='events-wrapper'>
            <h3>{title}</h3>
            {
                events.map((event) => 
                    <p className='events-row'>
                        <b>{event.date}</b>
                        <div>{event.event}</div>
                    </p>
                )
            }
        </div>
    );
}