import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { IconButton, Tooltip } from '@mui/material';
import '../assets/BottomBar.css';

export const BottomBar = () => {
    return (
        <div className='bottom-bar-wrapper'>
            <Tooltip title="Facebook">
                <IconButton className='facebook-button' target="_blank" href='https://www.facebook.com/SiVRFC' color='primary'> <FacebookIcon fontSize='large'/> </IconButton>
            </Tooltip>
            <Tooltip title="Instagram">
                <IconButton className='instagram' target="_blank" href='https://www.instagram.com/siliconvalleyrugby/'> <InstagramIcon fontSize='large'/> </IconButton>
            </Tooltip>
            <Tooltip title="LinkedIn">
                <IconButton className='facebook-button' target="_blank" href='https://www.linkedin.com/company/svrfc/' color='primary'> <LinkedInIcon fontSize='large'/> </IconButton>
            </Tooltip>
        </div>
    );
}