import { TitleBar } from '../components/TitleBar';
import '../assets/AboutUs.css';

export const AboutUsPage = () => {
    return (
        <>
            <TitleBar title='About Us'/>
            <div className='wrapper'>
                <div className='text-column'>
                    <div className='title'>OUR CLUB HISTORY</div>
                    <p>
                        Silicon Valley Rugby Football Club (SVRFC) was established in the year 2000 as a high school rugby club 
                        for the South San Francisco Bay Area.  In 2013, Silicon Valley Rugby merged with Santa Clara Youth Rugby, 
                        a youth rugby club based in Los Gatos.  The merge  brought players for U8 through middle school to the high 
                        school rugby club.  Our Men's side was founded in 2014.  We are excite to note that we recently expanded the 
                        Women's side in its inaugural side this past Summer 7s season (in 2023).  Achieving the clubs junior youth to 
                        adult rugby program.
                    </p>
                    <p>
                        SVRFC is a member of USA Rugby. Our junior youth and high school programs participate in the Bay Division of the 
                        Northern California Youth Rugby Association (NCYRA) during the winter.  Our Adult sides participates in Norcal 
                        Division 2 & 3 and have been  D4 champions in past years.
                    </p>
                    <p>During the summer, SVRFC plays the newest Olympic Sport: 7s Rugby.</p>
                    <p>
                        We host players from all over the Silicon Valley including Mountain View, Sunnyvale, Campbell, Fremont, Cupertino, 
                        Morgan Hill, Gilroy, Saratoga, Los Gatos, Santa Clara, Willow Glen, Cambrian, Alviso, and San Jose.  Silicon Valley 
                        Rugby hosts youth rugby teams for boys and girls ages 5 through 18.
                    </p>
                    <p>Silicon Valley Rugby Football Club is a registered 501(c)(3): FEIN: 27-0621542</p>
                    <p>Mailing Address:</p>
                    <p>209 EAST JAVA DRIVE <br></br>
                        PO BOX 61023 <br></br>
                        SUNNYVALE, CA 94089
                    </p>

                    <div className='title'>OUR MISSION, CORE VALUES AND CULTURE</div>
                    <p>At Silicon Valley RFC our mission is: To have a positive impact on peoples lives, through a thriving rugby club and community in Silicon Valley</p>
                    <p>Our club Core Values are:</p>
                    <ul>
                        <li>Community - Out of many, One</li>
                        <li>Selflessness - we strive for the greater good of the club and community</li>
                        <li>Focus - we are always moving forward</li>
                        <li>Accountability - we adhere to the highest standard</li>
                    </ul>
                    <p>Our culture and rugby philosophy</p>
                    <ul>
                        <li>Anyone can play rugby.  Everyone has something to contribute.</li>
                        <li>We believe in treating each player as an individual; each with unique qualities, needs, goals, and challenges.</li>
                        <li>We believe in creating an environment where hard work and good times can coexist. </li>
                        <li>We, as players, coaches and supporters, respect our opponents and our referee.  As Oscar Wilde said, "Rugby is a ruffians game, played by gentlemen".</li>
                        <li>Rugby is about making connections; lifelong friendships are built on the pitch through the common struggle of the game.</li>
                        <li>We believe in multi-sport athletes, and encourage it.</li>
                        <li>Rugby is the best game on Earth...it has the finesse of basketball, the stamina of soccer, and the physicality of "grid-iron" football and wrestling.</li>
                        <li>We believe rugby is true sport; a great teacher of lessons that apply far beyond the boundaries of the field, and the ultimate expression of both group values and the power of the individual.</li>
                    </ul>
                </div>
                <div className='picture-column'>
                    <img src="/images/youth-flag.jpg" alt="youth flag" width="300"/>
                    <img src="/images/youth-scrum.jpg" alt="youth scrum" width="300"/>
                    <img src="/images/womens-play.jpg" alt="womens-play" width="300"/>
                    <img src="/images/mens-play.jpg" alt="mens play" width="300"/>
                    <img src="/images/youth-hs-line.jpg" alt="youth hs line" width="300"/>
                    <img src="/images/paiges-ADD.jpg" alt="womens circle" width="300"/>
                    <img src="/images/mens-under-posts.jpg" alt="mens-under-posts" width="300"/>
                    <img src="/images/youth-line-break.jpg" alt="youth line break" width="300"/>
                    <img src="/images/womens-offload.jpg" alt="womens offload" width="300"/>
                    <img src="/images/jonesy-offload.jpeg" alt="jonesy offload" width="300"/>
                </div>
            </div>
        </>
    );
}